import React              from "react";
import { BiShow, BiHide } from "react-icons/bi";

import "./stylesCIF.css"

export default function CompInputForm({value, onChange, Icon, typeA, placeHolder, lock, maxLength, autoComplete, onBlur, pattern, array}){

  // lock true => ativa o sistema password
  const [viewP, setViewP] = React.useState(false); 
  const [type, setType]   = React.useState(typeA);
  
  return (
    <div className = "contain-comp-input-forms">
      { Icon &&
        <Icon
          size      = {25}
          color     = "#388E50"
        />
      }
      { typeA === "password" ? // Se o typeA for password, o input recebe o type, se não, recebe o typeA
        <input
          className     = "input-comp"
          placeholder   = {placeHolder}
          type          = {type}
          value         = {value}
          onChange      = {onChange}
          maxLength     = {maxLength}
          autoComplete  = {autoComplete}
        /> : 
        <input
          className     = "input-comp"
          placeholder   = {placeHolder}
          type          = {typeA}
          value         = {value}
          onChange      = {onChange}
          maxLength     = {maxLength}
          pattern       = {pattern}
          autoComplete  = {autoComplete}
          onBlur        = {onBlur}
        />
      }
      {
        lock && // Lock = true => Aparece o icone view ' 
        (viewP ? 
          <BiShow
            className = "icon-view-pass"
            size      = {20}
            onClick   = {() => {
              setViewP(!viewP);
              setType("password");
            }}
          /> :
          <BiHide
            className = "icon-view-pass"
            size      = {20}
            onClick   = {() => {
              setViewP(!viewP);
              setType("text");
            }}
          />
        )
      }
    </div>
  );

}