import React            from "react";

import "./stylesR.css";
import { FormRegister } from "../../components/Forms";


export default function Register() {

  return (
    <div className = "contain-max-center">
      <div className = "contain-mid-center">
        <div className = "contain-form-login">
          <FormRegister/>
        </div>
        { window.innerWidth > 767 &&
          <div className = "contain-carrossel">
            Carrossel de imagens e informações promocionais
          </div>
        }
      </div>
    </div>
  );
}