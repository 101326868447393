import React                from "react";
import { CompInputForm, Loading }    from "../../Basics";
import { BiLeftArrowAlt }   from "react-icons/bi";
import { TbNumbers }        from "react-icons/tb";
import { MdEmail, MdLock }  from "react-icons/md";
import { 
  confPassLogo, recPass, logoAppSolid, logoAppRemov
} from "../../../assets";
import Alerts                     from "../../Basics/AlertsSwall";
import validator                  from "validator";
import RouterHook                 from "../../../contexts/RouterHook";
import { AplicationContext }      from "../../../contexts/AplicationContext";
import { 
  LocalStorage, Services
} from "../../../services";

import "./stylesFL.css";

export default function FormLogin() {
  // Contexto de autenticação
  
  const serverRouter        =   RouterHook();
  const {
    actions, loading, state
  } = React.useContext(AplicationContext);
  const [ stt, setStt ]     =   React.useState({
    email                 : "motoraA@gmail.com",
    password              : "",
    cod                   : "",
    newPass               : "",
    confPass              : "",
    codOk                 : false,
    forca                 : "",
    states                : 0,
  });
  console.log("State: Aqui chamando!!!", state);

  React.useEffect(() => {
    actions.SetaModeContext("login");
  }, []);

  //Escuta a tecla enter para submeter o formulário
  React.useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        if(stt.states == 0)       actions.StartLogin(stt.email)
        else if(stt.states == 1)  StartRedefinirSenha(stt.email);
        else if(stt.states == 2)  StartValidToken(stt.email, stt.cod);
        else if(stt.states == 3)  StartRedefinirPass(stt.email, stt.newPass, stt.confPass);
      }
    }
    if(stt.states === 0)  document.title = "Acessar Conta | Move Bus";
    else                  document.title = "Atualizar Senha | Move Bus";
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [ stt ]);

  const hasOneHourPassed = (dtAtual, dtExp) => {
    const differenceInMilliseconds = dtAtual - dtExp;
    const differenceInHours = differenceInMilliseconds / 1000 / 60 / 60;
    //console.log("Diferença em horas: ", differenceInHours);

    return differenceInHours >= 1;
  }
  //const honue = LocalStorage.get("url-empresa");
  //console.log("Honue: ", honue);
  //LocalStorage.clear();
  
  // Inicia o processo de recuperação de senha, enviando o email
  async function StartRedefinirSenha(email) {
    if(validator.isEmail(email) === true){
      //setLoading(true); // Inicia o loading
      
      //setLoading(false); // Finaliza o loading, por trás dos panos!
    } else {
      //console.log("Email inválido!");
      Alerts.aviso("Atenção", "E-mail inválido, por favor verifique o e-mail informado e tente novamente.");
    }
  }
  // Inicia o processo de validação do token de recuperação de senha
  async function StartValidToken(email, cod) {
    if(validator.isEmail(email) === true){
      //setLoading(true); // Inicia o loading
      
        /// --------------------

      //setLoading(false); // Finaliza o loading, por trás dos panos!
    } else {
      Alerts.aviso("Atenção", "Não altere o e-mail informado, por favor volte e repita o processo novamenteo.");
    }
  }
  // Inicia o processo de redefinição de senha
  async function StartRedefinirPass(email, newPass, confPass) {
    if(validator.isEmail(email) === true){
      // Verifica se as senhas são iguais
      //setLoading(true); // Inicia o loading
      if(newPass === confPass){
        
        /// --------------------

      } else {
        Alerts.aviso("Atenção", "As senhas informadas não são iguais, por favor verifique e tente novamente.");
      }
      //setLoading(false); // Finaliza o loading, por trás dos panos!
    } else {
      Alerts.aviso("Atenção", "Não altere o e-mail informado, por favor volte e repita o processo novamenteo.");
    }
  }
  
  // Aviso de email invalido do usuário
  function EmailInvalido(val){
    if(val){
      Alerts.aviso("Atenção", "E-mail inválido, por favor verifique o e-mail informado e tente novamente.");
    } else {
      Alerts.aviso("Atenção", "Informe seu e-mail para prosseguir.");
    }
  }
  // Define o titulo do botão de acordo com o estado na redefinição de senha
  function ReturnLabelBtt(){
    if(stt.states === 1){
      return "Enviar código";
    } else if(stt.states === 2){
      return "Validar código";
    } else if(stt.states === 3){
      return "Redefinir senha";
    } else if(stt.states === 4){
      return "Acessar minha conta";
    }
  }
  // Renderiza o formulário de acordo com o estado
  function RenderFormRecSenha(){
    if(stt.states === 1){ // Pega o email, e solicita que o código de autenticidade seja enviado para o email
      return (
        <>
          <img className = "img-rec-pass-step-2" src = {recPass} alt = "Logo Agendei Quadras" />
          <h3 className = "text-label-center-h3">
            Enviaremos um código único que deve ser digitado na próxima etapa.
          </h3>
          <CompInputForm
            placeHolder = {"E-mail"}
            type        = {"email"}
            value       = {stt.email}
            onChange    = {(e) => setStt({...stt, email: e.target.value})}
            Icon        = {MdEmail}
            lock        = {false}
          />
        </>
      );
    } else if(stt.states === 2) { // Pega o código enviado por email, e valida se é realmente o código.
      return(
        <>
          <h3 className = "text-label-center-h3">
            Um e-mail foi enviado para você, insira o código informado para prosseguir!
          </h3>
          <CompInputForm
            placeHolder = {"Código"}
            type        = {"text"}
            value       = {stt.cod}
            onChange    = {(e) => setStt({...stt, cod: e.target.value})}
            Icon        = {TbNumbers}
            lock        = {false}
          />
          
        </>
      );
    } else if(stt.states === 3){ //  Pega a nova senha!
      return(
        <>
          <h3 className = "text-label-center-h3">
            Informe os campos abaixo para redefinir sua senha!
          </h3>
          <CompInputForm
            placeHolder = {"Nova Senha"}
            typeA       = {"password"}
            value       = {stt.newPass}
            onChange    = {(e) => {
              setStt({...stt, newPass: e.target.value, forca: Services.RetornaForcaSenha(e.target.value)});
            }}
            Icon        = {MdLock}
            lock        = {true}
          />
          <CompInputForm
            placeHolder = {"Confirmar Senha"}
            typeA        = {"password"}
            value       = {stt.confPass}
            onChange    = {(e) => setStt({...stt, confPass: e.target.value})}
            Icon        = {MdLock}
            lock        = {true}
          />
          
          {
            /* TODO ::> Implementar força de senha !!  */
            stt.forca &&
            <h4 
              className = "text-label-center-h3"
              style     = {{margin: "5px"}}  
            >
              {stt.forca}
            </h4>
          }
        </>
      );
    } else if(stt.states === 4){ //  Aviso de senha alterada!
      return (
        <>
          <img className = "img-rec-pass-step-2" src = {confPassLogo} alt = "Confirmação de senha alterada" />
          <h3 className = "text-label-center-h3">
            Agora acesse sua conta com a nova senha!
          </h3>
        </>
      );
    } 
  }
  //console.log("Developer", developer)
  if(stt.states == 0){ // Realiza o login
    return (
      <>
        {!loading ?
          <div className = "form-login">
            {/* Container com fomr para login */}
            <div className = "contain-inputs">
              <img
                className = "img-login-app"
                src       = {logoAppSolid}
              />
              <h2 className = "title-page">
                Acessar Conta
              </h2>
              <CompInputForm
                placeHolder   = {"E-mail"}
                type          = {"email"}
                value         = {stt.email}
                onChange      = {(e) => setStt({...stt, email: e.target.value})}
                Icon          = {MdEmail}
                lock          = {false}
                autoComplete  = {"email"}
              />
              <CompInputForm
                placeHolder   = {"Senha"}
                typeA         = {"password"}
                value         = {stt.password}
                onChange      = {(e) => setStt({...stt, password: e.target.value})}
                Icon          = {MdLock}
                lock          = {true}
                autoComplete  = {"current-password"}
              />
              <button className = "button-login"
                onClick = {() => {
                  actions.StartLogin(stt.email, stt.password);
                }}
                title   = "Clique para entrar"
              >
                Entrar
              </button>
              <a
                onClick = {() => {setStt({...stt, states: 1})}}
                title   = "Clique para recuperar sua senha"
              >Esqueci minha senha</a>
              <div className = "contain-bar-info-line">
                <div/>
                <label className = "text-label-low">
                  Ainda não tem uma conta?
                </label>
                <div/>
              </div>
              <button 
                className = "button-login"
                onClick   = {() => {
                  serverRouter("register");
                }}
                title     = "Clique para criar uma conta"
              >
                Cadastrar
              </button>
            </div>         
          </div>
            :
          <Loading
            text = {"Carregando..."}
            mode = {true}
          />
      }
      </>
    );
  } else { // Realiza a recuperação de senha
    return(
      <>
        { !loading ?
          <div className = "form-login-rec-pass">
            
            <div className = "contain-form-login-rec-pass">
              <BiLeftArrowAlt
                size      = {35}
                title     = "Clique para voltar"
                className = "icon-button-back"
                onClick   = {() => {
                  setStt({...stt, states: stt.states - 1});
                }}
              />
              <div className = "contain-inputs">
                <h1 className = "title-components-h1"> 
                  {stt.states !== 4 ? "Redefinição de Senha" : "Senha Redefinida"}
                </h1>
                {RenderFormRecSenha()}
                <button 
                  className = "button-login"
                  title     = "Clique para prosseguir"
                  onClick = {() => {
                    if(true){ //*Modo Prodcur
                      if(stt.states == 1)               StartRedefinirSenha(stt.email);
                      else if(stt.states == 2)          StartValidToken(stt.email, stt.cod);
                      else if(stt.codOk && stt.states == 3) StartRedefinirPass(stt.email, stt.newPass, stt.confPass);
                      else if(stt.states == 4)          setStt({...stt, states: 0 });
                    } else { //*Modo Teste
                      //* Modo Teste
                      if(stt.states == 0)      setStt({...stt, states: 1 });
                      if(stt.states == 1)      setStt({...stt, states: 2 });
                      else if(stt.states == 2) setStt({...stt, states: 3 });
                      else if(stt.states == 3) setStt({...stt, states: 4 });
                      else if(stt.states == 4) setStt({...stt, states: 0 });
                      //*/
                    }
                    
                  }}
                  >
                  {ReturnLabelBtt()}
                </button>  
              </div>
            </div>    
          </div>
            :
          <div className = "contain-loading">
            <Loading
              text = {"Carregando..."}
              mode = {true}
            />
          </div>
        }      
      </>
    );
  }
}