import React          from "react";

import "./stylesMC.css";

export default function MenuClient() {

  return(
    <div className = "contain-max-center">
      
    </div>
  );
}