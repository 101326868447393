import React                    from "react";
import { 
  BrowserRouter as Router, Routes, Route 
} from "react-router-dom";
import {
  AcessClient, AcessMotorista, Login, Menu, MenuClient, Register
} from "./screens/";
import AplicationProvider from "./contexts/AplicationContext";
import { LocalStorage } from "./services";

export default function AppRouter() {

  const labels = {
    acessClient   : "/",
    acessMotorista: "/motorista/:idmotorista",
    menuClient    : "/cliente/:idcliente",
    login         : "/entrar",
    register      : "/cadastrar",
    menu          : "/empresa/:idempresa",
  };
  //LocalStorage.clear();
  function Rotas(){
    return (
      <Routes>
        <Route path = {labels.acessClient}      element = {<AcessClient/>}        />
        <Route path = {labels.login}            element = {<Login/>}              />
        <Route path = {labels.register}         element = {<Register/>}           />
        <Route path = {labels.menu}             element = {<Menu/> }              />
        <Route path = {labels.menuClient}       element = {<MenuClient/> }        />
        <Route path = {labels.acessMotorista}   element = {<AcessMotorista/> }    />
      </Routes>
    );
  }

  return (
    <Router>
      <AplicationProvider>
        { Rotas() }
      </AplicationProvider>
    </Router>
  );
}