const rotaC_move = [
  {lat: -31.3144240, lng: -54.1088355},	
  {lat: -31.3144607, lng: -54.1090715},	
  {lat: -31.3144882, lng: -54.1093075},	
  {lat: -31.3145157, lng: -54.1095221},	
  {lat: -31.3145615, lng: -54.1096509},	
  {lat: -31.3147540, lng: -54.1095543},	
  {lat: -31.3149648, lng: -54.1095328},	
  {lat: -31.3151756, lng: -54.1094899},	
  {lat: -31.3154048, lng: -54.1094363},	
  {lat: -31.3156431, lng: -54.1094041},	
  {lat: -31.3159089, lng: -54.1093612},	
  {lat: -31.3161105, lng: -54.1093290},	
  {lat: -31.3163305, lng: -54.1092968},	
  {lat: -31.3165505, lng: -54.1092646},	
  {lat: -31.3167430, lng: -54.1092110},	
  {lat: -31.3169263, lng: -54.1091573},	
  {lat: -31.3171738, lng: -54.1091573},	
  {lat: -31.3174304, lng: -54.1090930},	
  {lat: -31.3177512, lng: -54.1090608},	
  {lat: -31.3180353, lng: -54.1090286},	
  {lat: -31.3180262, lng: -54.1086745},	
  {lat: -31.3179712, lng: -54.1084278},	
  {lat: -31.3179620, lng: -54.1081488},	
  {lat: -31.3178979, lng: -54.1078484},	
  {lat: -31.3178520, lng: -54.1076016},	
  {lat: -31.3175771, lng: -54.1076446},	
  {lat: -31.3172654, lng: -54.1076660},	
  {lat: -31.3170363, lng: -54.1077089},	
  {lat: -31.3167705, lng: -54.1077304},	
  {lat: -31.3165597, lng: -54.1077733},	
  {lat: -31.3163030, lng: -54.1077948},	
  {lat: -31.3160831, lng: -54.1078591},	
  {lat: -31.3158447, lng: -54.1078699},	
  {lat: -31.3156156, lng: -54.1078806},	
  {lat: -31.3154598, lng: -54.1079128},	
  {lat: -31.3152948, lng: -54.1079235},	
  {lat: -31.3150931, lng: -54.1079879},	
  {lat: -31.3148548, lng: -54.1080201},	
  {lat: -31.3145982, lng: -54.1080201},	
  {lat: -31.3144240, lng: -54.1080737},	
  {lat: -31.3143324, lng: -54.1081166},	
  {lat: -31.3143782, lng: -54.1083312},	
  {lat: -31.3144057, lng: -54.1084921},	
  {lat: -31.3144332, lng: -54.1086531},	
  {lat: -31.3144240, lng: -54.1087818},	
  {lat: -31.3144332, lng: -54.1087604},	
];

const rotaD_move = [
	{lat: -31.3143415, lng:	-54.1078591},
	{lat: -31.3146348, lng:	-54.1077948},
	{lat: -31.3148732, lng:	-54.1077518},
	{lat: -31.3151848, lng:	-54.1076875},
	{lat: -31.3154781, lng:	-54.1076446},
	{lat: -31.3157348, lng:	-54.1076016},
	{lat: -31.3160464, lng:	-54.1075802},
	{lat: -31.3163214, lng:	-54.1075587},
	{lat: -31.3166147, lng:	-54.1074944},
	{lat: -31.3168896, lng:	-54.1074729},
	{lat: -31.3172196, lng:	-54.1074085},
	{lat: -31.3175312, lng:	-54.1074085},
	{lat: -31.3178429, lng:	-54.1074085},
	{lat: -31.3181178, lng:	-54.1073012},
	{lat: -31.3184478, lng:	-54.1073012},
	{lat: -31.3188144, lng:	-54.1072583},
	{lat: -31.3191077, lng:	-54.1071725},
	{lat: -31.3194560, lng:	-54.1071510},
	{lat: -31.3198043, lng:	-54.1071081},
	{lat: -31.3202626, lng:	-54.1070008},
	{lat: -31.3206475, lng:	-54.1069794},
	{lat: -31.3210691, lng:	-54.1068721},
	{lat: -31.3213991, lng:	-54.1068077},
	{lat: -31.3217840, lng:	-54.1067433},
	{lat: -31.3221139, lng:	-54.1067219},
	{lat: -31.3225722, lng:	-54.1066360},
	{lat: -31.3229388, lng:	-54.1065931},
	{lat: -31.3232871, lng:	-54.1065073},
	{lat: -31.3235804, lng:	-54.1064858},
	{lat: -31.3235804, lng:	-54.1060138},
	{lat: -31.3235070, lng:	-54.1056919},
	{lat: -31.3233604, lng:	-54.1054344},
	{lat: -31.3230855, lng:	-54.1055202},
	{lat: -31.3228105, lng:	-54.1055632},
	{lat: -31.3225355, lng:	-54.1055632},
	{lat: -31.3222606, lng:	-54.1056275},
	{lat: -31.3219490, lng:	-54.1057134},
	{lat: -31.3217107, lng:	-54.1057134},
	{lat: -31.3214357, lng:	-54.1057563},
	{lat: -31.3211241, lng:	-54.1057563},
	{lat: -31.3207758, lng:	-54.1057992},
	{lat: -31.3204642, lng:	-54.1059065},
	{lat: -31.3202259, lng:	-54.1058850},
	{lat: -31.3199509, lng:	-54.1059065},
	{lat: -31.3196943, lng:	-54.1059279},
	{lat: -31.3194927, lng:	-54.1059279},
	{lat: -31.3192360, lng:	-54.1059709},
	{lat: -31.3189061, lng:	-54.1060781},
	{lat: -31.3185761, lng:	-54.1061425},
	{lat: -31.3182645, lng:	-54.1061640},
	{lat: -31.3179712, lng:	-54.1062069},
	{lat: -31.3177695, lng:	-54.1062927},
	{lat: -31.3174946, lng:	-54.1062498},
	{lat: -31.3172379, lng:	-54.1062927},
	{lat: -31.3169446, lng:	-54.1063786},
	{lat: -31.3166513, lng:	-54.1064429},
	{lat: -31.3163764, lng:	-54.1064429},
	{lat: -31.3161197, lng:	-54.1065073},
	{lat: -31.3158447, lng:	-54.1065502},
	{lat: -31.3155698, lng:	-54.1065931},
	{lat: -31.3152398, lng:	-54.1066360},
	{lat: -31.3150198, lng:	-54.1066575},
	{lat: -31.3147265, lng:	-54.1067219},
	{lat: -31.3144515, lng:	-54.1067219},
	{lat: -31.3141765, lng:	-54.1067648},
	{lat: -31.3141582, lng:	-54.1069794},
	{lat: -31.3141582, lng:	-54.1072154},
	{lat: -31.3142132, lng:	-54.1074300},
	{lat: -31.3142499, lng:	-54.1076875},
	{lat: -31.3144149, lng:	-54.1077733},
	{lat: -31.3142132, lng:	-54.1078591}
];

const rotaE_move = [
  {lat: -31.3144424, lng : -54.1088569},
  {lat: -31.3144699, lng : -54.1091359},
  {lat: -31.3145524, lng : -54.1094041},
  {lat: -31.3145340, lng : -54.1095972},
  {lat: -31.3147540, lng : -54.1095543},
  {lat: -31.3150381, lng : -54.1095114},
  {lat: -31.3152765, lng : -54.1094792},
  {lat: -31.3155056, lng : -54.1094470},
  {lat: -31.3157897, lng : -54.1093826},
  {lat: -31.3160647, lng : -54.1093504},
  {lat: -31.3163672, lng : -54.1092861},
  {lat: -31.3165688, lng : -54.1092539},
  {lat: -31.3168071, lng : -54.1092110},
  {lat: -31.3170180, lng : -54.1091788},
  {lat: -31.3172196, lng : -54.1091466},
  {lat: -31.3174762, lng : -54.1091251},
  {lat: -31.3176321, lng : -54.1090715},
  {lat: -31.3178154, lng : -54.1090500},
  {lat: -31.3180720, lng : -54.1090178},
  {lat: -31.3180720, lng : -54.1088569},
  {lat: -31.3180995, lng : -54.1086638},
  {lat: -31.3180353, lng : -54.1085243},
  {lat: -31.3179895, lng : -54.1084063},
  {lat: -31.3179345, lng : -54.1082346},
  {lat: -31.3179254, lng : -54.1080630},
  {lat: -31.3178887, lng : -54.1078699},
  {lat: -31.3178429, lng : -54.1076660},
  {lat: -31.3177787, lng : -54.1075480},
  {lat: -31.3176229, lng : -54.1076016},
  {lat: -31.3174121, lng : -54.1076231},
  {lat: -31.3172379, lng : -54.1076660},
  {lat: -31.3169905, lng : -54.1076875},
  {lat: -31.3168438, lng : -54.1076875},
  {lat: -31.3167155, lng : -54.1077304},
  {lat: -31.3165322, lng : -54.1077304},
  {lat: -31.3163397, lng : -54.1077840},
  {lat: -31.3161105, lng : -54.1078484},
  {lat: -31.3159456, lng : -54.1078806},
  {lat: -31.3157073, lng : -54.1078913},
  {lat: -31.3155698, lng : -54.1079128},
  {lat: -31.3154323, lng : -54.1079664},
  {lat: -31.3152581, lng : -54.1079557},
  {lat: -31.3150840, lng : -54.1079664},
  {lat: -31.3149007, lng : -54.1079664},
  {lat: -31.3147173, lng : -54.1080093},
  {lat: -31.3145340, lng : -54.1080093},
  {lat: -31.3143507, lng : -54.1080523},
  {lat: -31.3143507, lng : -54.1082990},
  {lat: -31.3143965, lng : -54.1085565},
  {lat: -31.3144240, lng : -54.1086853},
  {lat: -31.3144882, lng : -54.1087818},
  {lat: -31.3143782, lng : -54.1087496}
];

export { rotaC_move, rotaD_move, rotaE_move };