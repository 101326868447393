import React                from "react";
import {
  CompSelect, Loading
} from "../../components/Basics";
import socketIoClient       from "socket.io-client";
import { AplicationContext } from "../../contexts/AplicationContext";
import { Loader }           from "@googlemaps/js-api-loader"
import { gpsBus }           from "../../assets";
import { Services } from "../../services";

import "./stylesAM.css";

export default function AcessMotorista({}) {

  const mapRef  = React.useRef(null);
  const { state } = React.useContext(AplicationContext);
  const [sttPg, setSttPg]         = React.useState({
    mode      : "init", // || map
    empresa   : state.empresaSel,
    linhaSel  : "",
    position  : {lat: 0, lng: 0},
  });
  const [loading, setLoading]   = React.useState(false);
  const [markerMot, setMarkerMot] = React.useState(null); //marcador do motorista no mapa
  const [markers, setMarkers] = React.useState(null); //marcadores no mapa demais veículos

  React.useEffect(() => {
    document.title = "Motorista | Move Bus";
    
    let watchId;

    const opcoes = {
      // Opções como a precisão podem ser configuradas aqui
      enableHighAccuracy: true,  // Se precisar de uma precisão maior
      timeout: 15000,            // Tempo máximo em milissegundos para obter a localização
      maximumAge: 15000          // Idade máxima em milissegundos de um cache de posição
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (posicao) => {
          console.log("Permissão concedida. Posição atualizada: ", posicao);
          setSttPg(prevSttPg => ({
            ...prevSttPg,
            position: {
              lat: posicao.coords.latitude,
              lng: posicao.coords.longitude,
            },
          }));
          setMarkerMot(prevSttPg => ({
            ...prevSttPg,
            position: {
              lat: posicao.coords.latitude,
              lng: posicao.coords.longitude
            }
          }));
          // adicionar o marcador no mapa
          // Marcando o ícone no mapa com base na coordenada fornecida
        },
        (erro) => {
          console.error("Permissão negada ou erro ao observar a posição: ", erro);
        },
        opcoes
      );
      watchId = navigator.geolocation.watchPosition(
        (posicao) => {
          console.log("Posição atualizada: ", posicao);
          setSttPg(prevSttPg => ({
            ...prevSttPg,
            position: {
              lat: posicao.coords.latitude,
              lng: posicao.coords.longitude,
            },
          })); // Atualiza o estado para re-renderizar o componente
        },
        (erro) => {
          console.error("Erro ao observar a posição: ", erro);
        },
        opcoes
      );
    } else {
      console.error("Geolocalização não suportada pelo seu navegador.");
    }

    // Limpeza: parar de observar a posição quando o componente for desmontado
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };

  }, []);

  React.useEffect(() => {
    console.log("Executando useEffect ", sttPg.mode);
    console.log("Carregando mapa");
    const loader = new Loader({
      apiKey    : process.env.REACT_APP_MAPS_KEY,
      version   : "weekly",
      libraries : ["places"]
    });

    loader.load().then(() => {

      const map = new window.google.maps.Map(mapRef.current, {
        center: sttPg.position,
        zoom: 12,
      });

      // Marcando o ícone no mapa com base na coordenada fornecida
      const veicleMarker = new window.google.maps.Marker({
        position: sttPg.position,
        map: map,
        icon: {
          url: gpsBus, // url do ícone
          scaledSize: new window.google.maps.Size(45, 45) // Define o tamanho do ícone
        }
      });
      setMarkerMot(veicleMarker);
      console.log("Aqui", sttPg.linhaSel);
      if( sttPg.linhaSel === "" || !sttPg.linhaSel ) return;

      console.log("Linha selecionada!!", sttPg.linhaSel);
      const routeCoords     = Services.ConverteStringToPoints(sttPg.linhaSel.linhaRota);
      console.log("Rota: ", routeCoords);
      //const routeCoords   = sttPg.linhaSel.linhaRota.coordinates.map(coord => {return { lat: coord[1], lng: coord[0] }});

      // Criando uma Polyline para desenhar a rota no mapa
      new window.google.maps.Polyline({
        path          : routeCoords,
        geodesic      : true,
        strokeColor   : '#FF0000', // Cor da linha. Pode alterar conforme sua necessidade
        strokeOpacity : 1.0,
        strokeWeight  : 2,
      }).setMap(map);
      // Verificando se o objeto linhaSel e suas coordenadas estão disponíveis
    });
  }, [mapRef.current, sttPg.mode, sttPg.linhaSel]); // Removemos window.google da lista de dependências

  async function ConectToService(){
    console.log("Conectando ao serviço de rotas");
    const newSocket = socketIoClient("http://192.168.0.23:3005/connectionMotorista/", {
      // enviar dados de autenticação
      query: {
        idEmpresa     : state.empresaSel.idEmpresa,
        idMotorista   : state.user.idusuario,
        idLinha       : sttPg.linhaSel.idRota,
      },
      transports: ["websocket", "polling", "flashsocket"],
      //enviar token de autenticação
      auth: {
        token: state.token,
      },
    });
    console.log("Socket", newSocket);
    //mostrar console de erro
    newSocket.on("error", (err) => {
      console.log("Erro ao conectar ao serviço de rotas", err);
    });

    newSocket.on("connect", () => {
      console.log("Conectado ao serviço de rotas");
    });

    newSocket.on("disconnect", () => {
      console.log("Desconectado do serviço de rotas");
    });

  }

  function InitConect(){
    ConectToService().then(() => {
      console.log("Conectado ao serviço de rotas");
      
    });
  }


  return (
    <div 
      className = "contain-max-center"
      style     = {{ backgroundColor: "#114764"}}
    >
      { loading ? 
        <div className = "contain-loading">
          <Loading
            mode  = {true}
            text  = {"Carregando..."}
          /> 
        </div>
          :
        <div className = "contain-acess-menu">
          <h1 
            className = "title-page"
            style    = {{ marginTop: "10px", fontWeight: "bold", marginBottom: "0px"}}
          >
            Acessar como Motorista
          </h1>
          <div className = "contain-seleciona-empresa-linha">
            
            <div className = "contain-inputs-selects">
              {
                <CompSelect
                  value = {sttPg.linhaSel}
                  onChange = {(e) => {
                    console.log("Linhas: ", sttPg.empresa.linhas);
                    console.log("Linha selecionada", e, e.target.value);
                    setSttPg({
                      ...sttPg,
                      linhaSel: sttPg.empresa.linhas[e.target.value],
                    });
                  }}
                  placeHolder = {"Selecione uma linha"}
                  array       = {sttPg.empresa.linhas}
                />
              }
              {/* mostra google maps */}
              <div
                ref       = { mapRef }
                className = "contain-map-rota-select"
                style     = {{height: window.innerHeight - 230}}
              />
                
              <button
                className = "btt-entrar-rotas"
                onClick   = {() => {
                  InitConect();
                }}
              >
                Iniciar rota
              </button>
            </div>
          </div>
        </div>          
      }
    </div>
  );


  

}