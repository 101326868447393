import React                    from "react";
import { Alerts }               from "../components/Basics/";
import { UsuarioApi }           from "../servicesAPI";
import { LocalStorage }         from "../services";
import RouterHook               from "./RouterHook";
import messagens                from "../store/StringsMessagesAplication";
import validator                from "validator";
import md5                      from "md5";
//import ReducerAction          from "./ReducerActions";

const dataState = LocalStorage.get("data-state-movebus");

console.log("DataState: ", dataState);

const initialState = {
  usuario         : null,
  token           : null,
  cidade          : null,
  empresas        : [],
  empresaSel      : {linhas : []},
  isAuth          : false,
  map : {zoom : 15, center: {lat: -31.765537, lng: -52.337437}},
  markers   : [],
};

export const AplicationContext = React.createContext({
  functions : {

  },
  actions  : {
    StartLogin      : async (email, password) => {},
    StartCadastro   : async (nome, telefone, email, password) => {},
    BuscaEmpresas   : async ()  => {},

    SetMarker       : (marker)  => {},    
    SetaModeContext : (mode)    => {},
  },
  mode        : "init",   // init, login, cadastro, menu-motorista, menu-usuario
  loading     : true,
  state       : dataState || initialState,
  dispatch    : () => {},
});

function reducer(state, action){
  
  switch(action.type){

    case "login"              : return realizarLogin(state, action.payload);

    case "set-position"       : return setPosition(state, action.payload);

    case "set-empresas"       : return setEmpresas(state, action.payload);

    default                   : return state;
  }


}


export default function AplicationProvider({ children }) {

  const serverRouter            = RouterHook();
  const [ state, dispatch ]     = React.useReducer(reducer, dataState || initialState);
  
  const [ loading, setLoading ] = React.useState(true);
  const [ mode, setMode ]       = React.useState("init");
  let countStt = 0;

  React.useEffect(() => {
    //Salva state no localStorage
    if(mode === "init")             return;
    if(mode === "login" && loading) return setLoading(false);
    LocalStorage.set("data-state-movebus", state);
    countStt++;
    console.log("CountStt: ", countStt);
  }, [ state, mode ]);

  React.useEffect(() => {
   
  }, []);

  async function StartLogin(email, password) {

    if(validator.isEmail(email) === true){
      setLoading(true); // Inicia o loading
      //console.log("Email: ", email);
      //console.log("Senha: ", password);
      
      // Realizar autenticação no sistema
      const req = await UsuarioApi.AutenticarUsuario(email, password);
      console.log("Requisição: ", req);
      if(req.status){
        dispatch({type: "login", payload: req.req});
        serverRouter("menu-motorista", req.req.usuario.idUsuario);
      } else {
        Alerts.aviso("Atenção", messagens.erroComunic);
      }
      setLoading(false); // Finaliza o loading, por trás dos panos!
    } else {
      //console.log("Email inválido!");
      Alerts.aviso("Atenção", "E-mail inválido, por favor verifique o e-mail informado e tente novamente.");
    }
  }

  async function StartCadastro(nome, telefone, email, pass) {
    if(validator.isEmail(email) === true){
      setLoading(true); // Inicia o loading
      const body = {
        nome,
        telefone,
        email,
        senha : md5(pass),
      }
      const req = await UsuarioApi.CadastrarMotorista(body);
      console.log("Requisição: ", req);
      if(req.status){

      } else {

      }
      console.log("Email: ", email);
      console.log("Senha: ", pass);

      setLoading(false); // Finaliza o loading, por trás dos panos!
    } else {
      //console.log("Email inválido!");
      Alerts.aviso("Atenção", "E-mail inválido, por favor verifique o e-mail informado e tente novamente.");
    }
  }

  async function BuscaEmpresas(){
    const req = await UsuarioApi.BuscarEmpresasAtivas();
    console.log("BuscaEmpresas: ", req);
    if(req.status){
      dispatch({type: "set-empresas", payload: req.req});
    } else {
      Alerts.aviso("Atenção", messagens.erroComunic);
    }
    setLoading(false);
  }

  function SetaModeContext(mode){
    setMode(mode);
  }

  return (
    <AplicationContext.Provider
      value = {{
        functions : {
          
        },
        actions  : {
          StartLogin, StartCadastro, BuscaEmpresas, SetaModeContext,
        },
        loading  : loading,
        state    : state,
        dispatch : dispatch,
      }}
    >
      { children }
    </AplicationContext.Provider>
  )
}


function realizarLogin(state, payload){
  console.log("Payload: ", payload);
  return {
    ...state,
    usuario     : payload.usuario,
    token       : payload.token,
    empresaSel  : payload.usuario.perfil_usuario.empresa,
    isAuth      : true,
  }
}

function setPosition(state, payload){
  return {
    ...state,
    position : payload,
  }
}

function setEmpresas(state, payload){
  return {
    ...state,
    empresas : payload,
  }
}
