import React                      from "react";
import { CompInputForm, Loading } from "../../Basics";
import { TbNumbers }              from "react-icons/tb";
import { MdEmail, MdLock }        from "react-icons/md";
import Alerts                     from "../../Basics/AlertsSwall";
import validator                  from "validator";
import RouterHook                 from "../../../contexts/RouterHook";
import { AplicationContext }      from "../../../contexts/AplicationContext";
import { UsuarioApi }             from "../../../servicesAPI";
import md5                        from "md5";
import { 
 BiLeftArrowAlt, BiSolidPhone, BiSolidUserPlus 
} from "react-icons/bi";
import { 
  confPassLogo, logoAppSolid, recPass
} from "../../../assets";
import { 
  LocalStorage, Services
} from "../../../services";

import "./stylesFR.css";

export default function FormRegister() {
  
  // Contexto da aplicação
  const serverRouter = RouterHook();
  const {
    actions, loading
  } = React.useContext(AplicationContext);
  


  const [ stt, setStt ]     =   React.useState({
    nome                  : "WillianS",
    telefone              : "(53) 99999-9999",
    email                 : "williannikeA@gmail.com",
    password              : "qweqwe",
  });

  //Escuta a tecla enter para submeter o formulário
  React.useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        actions.StartCadastro(stt.nome, stt.email, stt.password);
      }
    }
    document.title = "Cadastrar Usuário | Move Bus";
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [ stt ]);

 
  //const honue = LocalStorage.get("url-empresa");
  //console.log("Honue: ", honue);
  //LocalStorage.clear();
  
  
  return (
    <>
      {!loading ?
        <div className = "form-login">
          <BiLeftArrowAlt
            className = "icon-back-page"
            onClick   = {() => serverRouter("back")}
          />
          {/* Container com fomr para login */}
          <div className = "contain-inputs">
            <img
                className = "img-login-app"
                src       = {logoAppSolid}
              />
            <h2 className = "title-page">
              Cadastrar Usuário
            </h2>
            <CompInputForm
              placeHolder   = {"Nome"}
              type          = {"text"}
              value         = {stt.nome}
              onChange      = {(e) => setStt({...stt, nome: e.target.value})}
              Icon          = {BiSolidUserPlus}
              lock          = {false}
              autoComplete  = {"name"}
            />
            <CompInputForm
              placeHolder   = {"Telefone"}
              type          = {"tel"}
              value         = {stt.telefone}
              onChange      = {(e) => setStt({...stt, telefone: e.target.value})}
              Icon          = {BiSolidPhone}
              lock          = {false}
              autoComplete  = {"tel"}
            />
            <CompInputForm
              placeHolder   = {"E-mail"}
              type          = {"email"}
              value         = {stt.email}
              onChange      = {(e) => setStt({...stt, email: e.target.value})}
              Icon          = {MdEmail}
              lock          = {false}
              autoComplete  = {"email"}
            />
            <CompInputForm
              placeHolder   = {"Senha"}
              typeA         = {"password"}
              value         = {stt.password}
              onChange      = {(e) => setStt({...stt, password: e.target.value})}
              Icon          = {MdLock}
              lock          = {true}
              autoComplete  = {"current-password"}
            />
            <button className = "button-login"
              onClick = {() => {
                actions.StartCadastro(stt.nome, stt.telefone, stt.email, stt.password);
              }}
              title   = "Clique para entrar"
            >
              Cadastrar
            </button>
          </div>         
        </div>
          :
        <Loading
          text = {"Carregando..."}
          mode = {true}
        />
    }
    </>
  );
 
}