
const Services = {
  ConverteStringToPoints    : (rota) => {
    return rota.map(coordString => {
      const [lng, lat] = coordString.split(' ').map(Number); // Convert strings to numbers
      return { lat, lng }; // Retornando no formato esperado
    });
  }  
};

export default Services;