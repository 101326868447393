import React from 'react';
import './stylesLg.css';

export default function Loading({text, mode}){

  return (
    <div className="contain-load">
      <div className="load-spinner"/>
      { (mode) &&
        <label className = "load-text-white">
          {text}
        </label>
      }
    </div>
  );
};