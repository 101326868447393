import React from "react";

import "./stylesCS.css";

export default function CompSelect({value, onChange, placeHolder, array}){

  //console.log("Empresas: ", array);

  return (
    <select
      value         = {value}
      className     = "contain-select-user"
      onChange      = {onChange}
    >
      <option value = "" disabled hidden>
        {placeHolder}
      </option>
      {
        array.map((item, index) => {
          return (
            <option
              key   = {index}
              value = {index}
            >
              {item.nome}
            </option>
          );
        })
      }
    </select>
  );
}