import Swal from 'sweetalert2';

const sucesso = (title, text) => {
  return Swal.fire({
    icon    : 'success',
    title   : title,
    text    : text,
    timer   : 6000,
    confirmButtonText : 'Entendi',
    confirmButtonColor: '#DA5A34',
  });
}

const aviso = (title, text) => {
  return Swal.fire({
    icon    : 'warning',
    title   : title,
    text    : text,
    timer   : 8000,
    confirmButtonText : 'Entendi',
    confirmButtonColor: '#DA5A34',
  });
}

const Alerts = {
  sucesso, aviso
}

export default Alerts;