import React            from "react";

import "./stylesL.css";
import { FormLogin } from "../../components/Forms";


export default function Login() {

  return (
    <div className = "contain-max-center">
      <div className = "contain-mid-center">
        <div className = "contain-form-login">
          <FormLogin/>
        </div>
        { window.innerWidth > 767 &&
          <div className = "contain-carrossel">
            Carrossel de imagens e informações promocionais
          </div>
        }
      </div>
    </div>
  );
}