const LocalStorage = {
  get : (key) => {
    const data = localStorage.getItem(key);
    console.log("Data: ", data);
    if(data === "undefined" || data === undefined || data === null) return null;
    return JSON.parse(data);
  },
  set : (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  remove : (key) => {
    return localStorage.removeItem(key);
  },
  clear : () => {
    return localStorage.clear();
  },
}; export default LocalStorage;