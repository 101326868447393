import React from 'react';
import { useNavigate } from 'react-router-dom';


export default function RouterHook(){
  const navigate    = useNavigate();
  const serverRouter = (rota, query) => {
    switch(rota){
      
      case "back"              : return navigate(-1);

      case "login"             : return navigate(`/entrar/`);

      case "register"          : return navigate(`/cadastrar/`);
      
      //case "menu-empresa"       : return ;

      case 'menu-motorista'     : return navigate(`/motorista/${query}`);

      default                  : return navigate(`/`);
      
     }
  }

  return serverRouter;
}
